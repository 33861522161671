@use '@angular/material' as mat;
@include mat.core();
@import '@angular/material';

@import './app/shared/styles/_variables.scss';
@import './app/shared/styles/_normalize.scss';
@import './app/shared/styles/_reboot.scss';
@import './palette.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto');

$my-app-primary: mat.define-palette(($primary));
$my-app-accent: mat.define-palette(mat.define-palette($secondary));
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-app-primary,
        accent: $my-app-accent,
        warn: $my-app-warn,
      ),
    )
);

html {
  @include mat.all-component-themes($my-theme);
}

html {
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: 500;

  body {
    height: 100vh;
    min-width: 400px;
  }
}

li {
  list-style-type: none;
}

.hidden {
  display: none !important;
}

.btn {
  background-color: $light-grey !important;
  color: $primary-blue;
  border-radius: 10px !important;
}
.btn-primary {
  background-color: $primary-blue !important;
  color: $white !important;
  border-radius: 10px !important;;
}
.btn-disabled {
  background-color: $light-grey !important;
  color: $gray !important;
  cursor: not-allowed;
  border-radius: 10px;
}
.icon-btn {
  cursor: pointer;
  background-color: $light-grey;
  border: 0;
  width: 35px;
  height: 35px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  mat-icon {
    line-height: 24px;
    height: 24px;
    width: 24px;
    font-size: 24px;
    padding: 0;
    overflow: visible;
  }

  &:hover {
    background-color: darken($light-grey, 5%)
  }
}
