$primary-blue: #1F4397;
$secondary-blue: #3165F0;
$green: #30D3C6;
$gray: #7B7B7B;
$light-grey: #EFEFEF;
$white: #F8F8F8;

$ntGreen: #30D3C6;
$ntDarkGreen: #26A99F;
$ntDarkGray: #090909;
$ntAzureBlue:  #3165F0;
$ntMarianBlue:  #1F4397;
$ntCoralBlue:  #0D1741;
$lightGray: #dcdfe0;
$darkGray: #7d7d7d;

$ntTextColor: #455a64;

$ntGray: #26353c;
$ntGrayLight: #3c4a50;
$toggleColor: #b6bbbd;
$link-active: #1f2a30;

// Create grayscale
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #f8f8f8 !default;
$body-color: $gray !default;
$inverse-bg: #fff !default;
$inverse-color: #fff !default;

// Fonts
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: Roboto, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-xs: 0.75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$dt-font-weight: $font-weight-bold !default;

$text-muted: $gray-light !default;

//// Links
////
//// Style anchor elements.
//
$link-color: #000 !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Others
$cursor-disabled: not-allowed !default;
